.frame {
  width: 100%;
  height: 100%;
  max-height: 65vh;
  aspect-ratio: 1 / 1.8;
  border-radius: 20px;
}

@media (min-width: 768px) {
  .frame {
    max-height: 75vh;
  }
}

@media (min-width: 1000px) {
  .frame {
    max-height: 65vh;
  }
}
