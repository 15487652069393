.feedback-progress-bar-container {
    position: fixed; /* Fix the element in place */
    top: 0;          /* Align to the top of the screen */
    left: 0;         /* Align to the left of the screen */
    width: 100%;     /* Full width */
    z-index: 9000;     /* High stacking order */
}

.feedback-progress-bar {
    height: 3px; /* Equivalent to `h-1` (1/4 of a rem or 4px) */
    background-color: #38bdf8; /* Equivalent to `bg-sky-400` */
    transition: all 200ms ease-out; /* Equivalent to `transition-all duration-50 ease-out` */
}


.feedback-overlay {
    position: fixed; /* Fix the element in place */
    top: 0;          /* Set top inset to 0 */
    right: 0;        /* Set right inset to 0 */
    bottom: 0;       /* Set bottom inset to 0 */
    left: 0;         /* Set left inset to 0 */
    background-color: rgba(255, 255, 255, 0.19); /* Semi-transparent white background */
    backdrop-filter: blur(0.5px); /* Apply slight blur to the background */
    pointer-events: none; /* Prevent user interaction */
    animation: fadeOut 300ms ease-out; /* Add fade-out animation */
    z-index: 9000; /* Set stacking order */
  }
  
  @media (prefers-color-scheme: dark) {
    .overlay {
      background-color: rgba(0, 0, 0, 0.08); /* Semi-transparent black background for dark mode */
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
