body {
  margin: 0;
  height: 100%;
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Intruding Cat";
  src: url("../public/fonts/MM-IntrudingCat/IntrudingCat.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../public/fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("../public/fonts/Roboto/Roboto-Thin.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../public/fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../public/fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../public/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("../public/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Fira Sans", sans-serif;
  src: url("../public/fonts/Fira-Sans/FiraSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fira Sans", sans-serif;
  src: url("../public/fonts/Fira-Sans/FiraSans-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Fira Sans", sans-serif;
  src: url("../public/fonts/Fira-Sans/FiraSans-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

.font-cat {
  font-family: "Intruding Cat";
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-fira-regular {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-fira-medium {
  font-family: "Fira Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.option-item::before {
  content: url("./components/icons/checkbox2.svg");
  background-color: #cee0f1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px !important;
  height: 20px !important;
  /* position: absolute;
  top: 2px;
  left: 0; */
  margin-right: 10px;
}

.no-select {
  -webkit-user-select: none; /* Para navegadores basados en WebKit (Chrome, Safari, etc.) */
  -moz-user-select: none;    /* Para Firefox */
  -ms-user-select: none;     /* Para Internet Explorer */
   user-select: none;       /* Estándar CSS */
}

.text2lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}